import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { RichTextOptions } from '../helpers/rich-text-options.js'

import '../graphql/fragments/graphElementLinkItem'
import LinkItem from '../components/link-item'
import { GatsbyImage } from 'gatsby-plugin-image'

const MemberTemplate = ({ data }) => {
  let bio = data?.contentfulCptMemberJson?.bio
    ? data.contentfulCptMemberJson.bio
    : false
  let name = data?.contentfulCptMemberJson?.name
    ? data.contentfulCptMemberJson.name
    : false
  let position = data?.contentfulCptMemberJson?.position
    ? data.contentfulCptMemberJson.position
    : false
  let photo =
    data?.contentfulCptMemberJson?.photo.gatsbyImageData.images.sources[0]
      .srcSet.childImageSharp.gatsbyImageData

  let link = data.allContentfulOptionsFooterJson.edges[0].node.leadershipPage

  let sectionClass = `hero-banner hero-banner--member hero-banner--dark hero-banner--low rel-wrap`
  return (
    <Layout header="dark">
      <section className={sectionClass}>
        <div className="grid-container rel-content">
          <div className="grid-x grid-margin-x align-middle">
            <div className="cell large-9 align-self-middle">
              <div className="hero-banner__content">
                <h1 className="hero-banner__title">{name}</h1>
                {position && (
                  <h4 className="hero-banner__subtitle green-text">
                    {position}
                  </h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-block member-info">
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell large-8 larger-order-1 small-order-2">
              <div className="member-info__content small-text">
                {bio && renderRichText(bio, RichTextOptions)}
              </div>
            </div>

            <div className="cell large-4 medium-9 member-info__photo-box large-order-2 small-order-1">
              {photo && (
                <GatsbyImage
                  image={photo}
                  alt={name}
                  className="member-info__photo"
                />
              )}
            </div>

            <div className="cell member-info__back-box small-order-3">
              {link && (
                <LinkItem
                  cssClass={'button hollow secondary'}
                  link={link}
                ></LinkItem>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({ data }) => {
  return <Seo title={data?.contentfulCptMemberJson?.name} />
}

export default MemberTemplate

export const query = graphql`
  query GetMember($slug: String!) {
    contentfulCptMemberJson(slug: { eq: $slug }) {
      id
      slug
      position
      photo {
        title
        gatsbyImageData {
          height
          images {
            sources {
              sizes
              type
              srcSet {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
      name
      bio {
        raw
      }
    }
    allContentfulOptionsFooterJson(
      filter: { optionsId: { eq: "options_footer" } }
    ) {
      edges {
        node {
          leadershipPage {
            title
            linkType
            id
            internalPage {
              id
              pageSlug
              pageTitle
            }
          }
        }
      }
    }
  }
`
